<template lang="pug">
//- v-dialog(:value="value" @input="$emit('input',$event)"
    fullscreen
    hide-overlay
    transition="dialog-top-transition")
t-window(v-bind="$attrs" v-on="$listeners" min-height="300px")
  template(slot="header") Прайс Лист
  template(slot="footer"): v-btn(color="primary" outlined x-large block depressed) Загрузить Прайс Лист
  v-list
    template(v-for="(c,index) in categories")
      v-list-item(Zthree-line)
        v-list-item-avatar(rounded)
          v-icon.grey(light) {{c.icon}}
        v-list-item-content
          v-list-item-title {{c.title}}
          v-list-item-subtitle Subtitle
        v-list-item-action
          v-btn(icon): v-icon(color="grey lighten-1") mdi-menu-right
      v-divider(v-if="index < categories.length - 1" :key="index")
</template>
<script>
import { mapState, mapActions } from 'vuex'

export default {
  components: {},
  data: () => ({
  }),

  computed: {
    ...mapState({
      categories: (state) => state.tjtheme.categories,
    }),
  },
  methods: {
    ...mapActions({
    }),
  },
}
</script>
<style lang="stylus"></style>
